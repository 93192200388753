import React, { useRef, useEffect, useCallback } from "react";
import {withGoogleMap, GoogleMap, Marker} from "react-google-maps"
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer"

const centerAddress = { address: 'Minsk, Belarus', lat: 53.9006011, lng: 27.558972 }

const FormGoogleMap = withGoogleMap((props) => {
  const mapRef = useRef();
  const updateRef = useRef(null);

  const update = useCallback(() => {
    clearTimeout(updateRef.current);
    updateRef.current = setTimeout(() => {
      const params = {
        bounds: mapRef.current.getBounds(),
        zoom: mapRef.current.getZoom(),
        center: {
          lat: mapRef.current.getCenter().lat(),
          lng: mapRef.current.getCenter().lng(),
        }
      };
      props.callbackUpdate(params);
    }, 300);
      // eslint-disable-next-line
  }, [mapRef, props.callbackUpdate]);

  useEffect(() => () => clearTimeout(updateRef.current), []);


  return (
    <GoogleMap
      ref={mapRef}
      onDragEnd={update}
      onZoomChanged={update}
      defaultZoom={7}
      defaultCenter={centerAddress}
      defaultOptions={{
        maxZoom: 12,
        minZoom: 6,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false}}
    >
      {props.children}
    </GoogleMap>
  );
});


const FormMap = props => {

    const {data, callbackBoundaries, callback} = props;

    const boundsUpdate = (params) => {
      callbackBoundaries(params);
    }

    const markerHandler = (item) => {
        callback(item);
    }


    const markers = (data) ? data.map((item) =>
        <div key={item._id}><Marker clickable={true} onClick={() => markerHandler(item)} position={{ lat: Number(item.lat), lng: Number(item.lng) }} /></div>) : [];

    return (
      <FormGoogleMap
        initialCenter={centerAddress}
        callbackUpdate={boundsUpdate}
        initialZoom={props.initialZoom}
        initialBounds={props.initialBounds}
        loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
        containerElement={<div style={{ height: `100vh`, width: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      >
          <MarkerClusterer
                averageCenter
                enableRetinaIcons
                calculator={(markers) => {
                    if (markers.length >= 10) return {text: "", index: 3}; // red
                    if (markers.length >= 5) return {text: "", index: 2};  // yellow
                    return {text: "", index: 0}; //blue
                    }
                }
                defaultMaxZoom={10}
          >
            {[markers]}
          </MarkerClusterer>
      </FormGoogleMap>
    )
}

export default FormMap
