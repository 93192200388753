const updateData = (state, action) => {

    if (state === undefined) {
        return {
            data: [],
            media: {},
            dataLoading: true,
            error: null
        };
    }

    switch (action.type) {
        case 'FETCH_DATA_REQUEST':
            return {
                ...state.data,
                dataLoading: true
            };

        case 'FETCH_DATA_SUCCESS': {
            const data = [];
            action.payload.forEach(item => {
                if (item.type === 'text') {
                    data.push({
                        ...item,
                        lng: item.geometry.coordinates[0],
                        lat: item.geometry.coordinates[1]
                    })
                } else {
                    item.attachments.forEach((attachment, index) => {
                        data.push({
                            ...attachment,
                            description: item.description,
                            area: item.area,
                            region: item.region,
                            community: item.community,
                            direction: attachment.direction || item.direction,
                            type: attachment.type || item.type || 'text',
                            url: attachment.url || attachment.value,
                            thumb: attachment.thumb || attachment.url || attachment.value || item.thumb || item.url,
                            _id: `${item._id}-${index}`,
                            lng: item.geometry?.coordinates[0],
                            lat: item.geometry?.coordinates[1]
                        })
                    })
                }
            })

            console.log(data)

            return {
                ...state.data,
                data: data,
                dataLoading: false,
            }
        }

        case 'FETCH_ITEM_SUCCESS':
            return {
                ...state.data,
                media: action.payload,
                dataLoading: false,
            };

        case 'FETCH_DATA_FAILURE':
            return {
                ...state.data,
                error: action.payload
            };

        default:
            return state.data;
    }
};

export default updateData;
