import React, {useState} from "react";
import ActivityCard from "./activity-card";
import PagesController from "../controls/pages-controller";

const Activities = props => {

    const {items: {area, data}, callback} = props

    const protestActivities = data.filter(item => item.direction === 'protest');
    const newsActivities = data.filter(item => item.direction === 'news');
    const creativeActivities = data.filter(item => item.direction === 'creative');

    const [page, setPage] = useState(0)

    return (
        <div>
            <h4 className='mb-20'>{area}</h4>
            <PagesController page={page} setPage={setPage} labels={['Все', 'Протесты', 'Новости', 'Креатив']}>
                <div className='mt-20 d-flex-wrap'>
                    {data.map((item, index) => <ActivityCard key={index} item={item} callback={() => callback(item)} />)}
                </div>
                <div className='mt-20 d-flex-wrap'>
                    {protestActivities.map((item, index) => <ActivityCard key={index} item={item} callback={() => callback(item)} />)}
                </div>
                <div className='mt-20 d-flex-wrap'>
                    {newsActivities.map((item, index) => <ActivityCard key={index} item={item} callback={() => callback(item)} />)}
                </div>
                <div className='mt-20 d-flex-wrap'>
                    {creativeActivities.map((item, index) => <ActivityCard key={index} item={item} callback={() => callback(item)} />)}
                </div>
            </PagesController>
        </div>
    );
}

export default Activities

