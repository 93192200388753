import React from "react";
import {FiFileText} from "react-icons/fi";
import {getTextDirection} from "../../utils/utils";

const ActivityCard = props => {

    const {item, callback} = props

    const descriptiontext = item.description.substring(0, 200) + (item.description.length > 200 ? '...' : '');

    return (
        <div onClick={callback} className='card-activity-container wp-100 mrweb-20 enabledClick'>
            {(item.type !== 'text' && item.type !== 'link') &&
                <img style={{width: '20%', maxHeight: 120, objectFit: 'cover'}} src={item.thumb} alt='activity'/>
            }
            {(item.type === 'text' || item.type === 'link') &&
            <div className='d-flex-center wp-20 mr-10'>
                <FiFileText size={14} color='#aaaaaa' />
                <p className='fs-8 fcblack ml-5'>{getTextDirection(item.direction || 'protest')}</p>
            </div>
            }
            <p className='ml-10 fs-12' style={{width: '80%'}}>{descriptiontext}</p>
        </div>
    );
}

export default ActivityCard

